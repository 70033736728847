import axios from "axios";

export interface UsePaymentSessionProps {
  publicToken: string;
}

export const usePaymentSession = async ({
  publicToken,
}: UsePaymentSessionProps) => {
  try {
    // const res = await fetch(
    //   `${process.env.GATSBY_CONCAVE_SERVER_URL}/monetico/paymentSession`,
    //   {
    //     method: "POST",
    //     mode: "no-cors",
    //     body: JSON.stringify({ publicToken }),
    //   }
    // );
    const { data } = await axios.post(
      `${process.env.GATSBY_CONCAVE_SERVER_URL}/monetico/paymentSession`,
      {
        publicToken,
      }
    );
    console.log("res usePayment", data);
    // if (res.status === 200) {
    return data;
    // }
  } catch (e) {
    console.log("error in usePayyment", e);
  }
  return null;
};
