import React, { useEffect } from "react";
import { usePaymentSession } from "../api/usePaymentSession";
import * as styles from "../styles/pages/checkoutRedirect.module.scss";
import { navigate } from "gatsby";
import { Logo } from "../components/Logo/Logo";
import { Theme } from "../components/Socials/Socials";

const CheckoutRedirect = ({ location }: any) => {
  useEffect(() => {
    const params = new URL(location.href).searchParams;
    const publicToken = params.get("publicToken") ?? "";

    if (!publicToken) navigate("/");

    const fetchSession = async () => {
      const res = await usePaymentSession({ publicToken });
      console.log("res", res);
      if (res === null) navigate("/");
      window.location.href = res.url;
    };

    fetchSession();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Logo theme={Theme.black} />
      </div>
      <div className={styles.content}>
        <img src="/images/redirected.svg" className={styles.icon} />
        <h1>You are being redirected, please do not close this page.</h1>
      </div>
    </div>
  );
};

export default CheckoutRedirect;
